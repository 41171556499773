<template>
  <div class="relative overflow-hidden" @mouseover="isHovering.value = true" @mouseleave="isHovering.value = false">
    <swiper
      :slides-per-view="slidesPerView"
      :space-between="spaceBetween"
      :breakpoints="breakpoints"
      :center-insufficient-slides="centerInsufficientSlides"
      @swiper="onSwiper"
      @slide-change="onSlideChange"
      :class="[compact && 'w-[90%] !overflow-visible']"
    >
      <slot />
    </swiper>
    <Stack
      v-if="(isHovering.value || alwaysShowArrows) && (items?.length || 5) > (slidesPerView || 4)"
      class="hidden sm:block"
    >
      <div
        :class="[
          'swiper-button [ pointer-events-auto cursor-pointer ] [ absolute left-none rounded-r-sm ] [ flex justify-center items-center ]',
          withDarkBackground ? 'bg-charcoal-default' : 'bg-transparent',
          alwaysShowArrows ? 'opacity-100' : 'opacity-50 hover:opacity-100',
        ]"
        @click="prevSlide()"
        v-if="!isBeginning.value && isHovering.value === true"
      >
        <Icon
          name="chevron-left"
          :color="withDarkBackground ? 'white' : 'charcoal-default'"
          :size="withDarkBackground ? 16 : 24"
        />
      </div>
      <div
        :class="[
          'swiper-button [ pointer-events-auto cursor-pointer ] [ absolute right-none rounded-l-sm ] [ flex justify-center items-center ]',
          withDarkBackground ? 'bg-charcoal-default' : 'bg-transparent',
          alwaysShowArrows ? 'opacity-100' : 'opacity-50 hover:opacity-100',
        ]"
        @click="nextSlide()"
        v-if="!isEnd.value && isHovering.value === true"
      >
        <Icon
          name="chevron-right"
          :color="withDarkBackground ? 'white' : 'charcoal-default'"
          :size="withDarkBackground ? 16 : 24"
        />
      </div>
    </Stack>

    <Stack
      direction="row"
      align="center"
      justify="center"
      class="mt-sm md:mt-2xs"
      gap="2xs"
      v-if="displayPaginationDots && items && items.length > 1"
    >
      <button
        v-for="(_, index) in items"
        :key="index"
        @click="changeSlideIndex(index)"
        :aria-label="`Go to slide ${index + 1}`"
        :class="[
          'rounded-round p-[5px]',
          active === index
            ? paginationDotsConfig[customDotsTheme]?.active
            : paginationDotsConfig[customDotsTheme]?.inactive,
        ]"
      />
    </Stack>
  </div>
</template>

<script lang="ts" setup>
import { SliderProps } from "./types";
import { Swiper } from "swiper/vue";

const emit = defineEmits(["swiper", "slideChange"]);

withDefaults(defineProps<SliderProps>(), {
  draggable: true,
  withDarkBackground: true,
  alwaysShowArrows: false,
  displayPaginationDots: false,
  customDotsTheme: "white-black",
  compact: false,
});

let swiperInstance: any = null;
const active = ref<number>(0);
const isHovering = reactive({ value: false });
const isBeginning = reactive({ value: true });
const isEnd = reactive({ value: false });

const paginationDotsConfig = {
  "orange-gray": {
    active: "bg-mkm-yellow-default",
    inactive: "bg-grey-default",
  },
  "white-black": {
    active: "bg-white",
    inactive: "bg-charcoal-default",
  },
};

const changeSlideIndex = (index: any) => {
  if (typeof index === "number") {
    active.value = index;
    swiperInstance.slideTo(index);
  } else if (typeof index === "object") {
    active.value = index.activeIndex;
  }

  emit("slideChange", index);
};

const onSlideChange = (swiper: any) => {
  isBeginning.value = swiper.isBeginning;
  isEnd.value = swiper.isEnd;
  changeSlideIndex(swiper);
};

const onSwiper = (swiper: any) => {
  swiperInstance = swiper;
  emit("swiper", swiper);
};

const prevSlide = () => {
  if (swiperInstance?.slidePrev) swiperInstance.slidePrev();
};

const nextSlide = () => {
  if (swiperInstance?.slideNext) swiperInstance.slideNext();
};
</script>

<style scoped>
.swiper-button {
  width: 40px;
  height: 96px;
  margin: 0 !important;
  border: none;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
}
</style>
